import styled from 'styled-components'
import { Button } from '@mui/material'
import Logo from '../assets/nextbreath.png'
// import { useNavigate } from 'react-router-dom'
import { isDesktop } from 'react-device-detect'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${props => props.bg}) no-repeat center center;
  background-size: contain;
  min-height: 100vh;
`

const Text = styled.span`
  font-size: 16px;
`

const Heading = styled.span`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 1rem;
`

const PasswordChanged = ({ logo, icon, bg }) => {
  // const navigate = useNavigate()

  return (
    <Main bg={bg}>
      <img
        style={{
          margin: '3rem 0',
          height: isDesktop ? '80px' : '50px',  // Adjust the height as needed
          width: isDesktop ? '300px' : '190px',  // Adjust the width as needed
        }}
        src={Logo}
        alt='Logo'
      />
      {!isDesktop && <Heading>Password Changed</Heading>}
      <img style={{ margin: '3rem 0' }} src={icon} alt='Logo' />
      <Text>Your password has been changed successfully.</Text>
      <Button
        variant='contained'
        sx={{
          textTransform: 'none',
          backgroundColor: '#002CB6',
          width: isDesktop ? '40%' : '80%',
          color: 'white',
          marginTop: '3rem',

          '&:hover': {
            backgroundColor: '#0038E5',
          },
        }}
        onClick={() => window.location.href = 'https://www.nextbreathapp.com/'}
      >
        Return to Home Page
      </Button>
    </Main>
  )
}

export default PasswordChanged
